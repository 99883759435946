import { env } from "@/env.mjs"

export const Cookies = {
  Email: "email",
  PassCode: "passCode",
  Cooldown: "cooldown",
  State: "state",
  Slug: "slug",
  CourseKey: "courseKey",
  UserSessionKey: env.NEXT_PUBLIC_USER_SESSION_KEY,
  MemberSessionKey: env.NEXT_PUBLIC_MEMBER_SESSION_KEY,
  SupabaseAccessToken: "supabase_accessToken",
  Provider: "provider",
}

export const Url = {
  Brand: "playboard.cloud",
  Facebook: "https://www.facebook.com",
  Tiktok: "https://www.tiktok.com",
  Instagram: "https://www.instagram.com",
  Youtube: "https://www.youtube.com",
  Line: "https://line.me/ti/p",
  LineOA: "https://lin.ee/oSe733U",
}

export const Provider = {
  Google: "google",
  Line: "line-login",
}

export const LOGIN = "login"
export const REGISTER = "register"
export const ACTIVATE = "activate"

export const unknownError = "An unknown error occurred. Please try again later."

export const PASSWORD_LOWER_THAN_8 = "ความยาวอย่างน้อย 8 ตัวอักษร"
export const PASSWORD_UPPERCASE = "มีตัวพิมพ์ใหญ่"
export const PASSWORD_LOWERCASE = "มีตัวพิมพ์เล็ก"
export const PASSWORD_NUMBER = "มีตัวเลข"
export const PASSWORD_SPECIAL_CHAR = "มีอักขระพิเศษ เช่น !, @, #, ?"

export const OTHER_OPTION = "อื่น ๆ (โปรดระบุ)"

export const MAX_FILE_SIZE = 30 * 1024 * 1024 * 1024
export const BLACKLIST_FILE_TYPE = [
  ".bmp",
  ".gif",
  ".jpeg",
  ".jpg",
  ".png",
  ".tif",
  ".tiff",
  ".svg",
  ".avif",
  ".webp",
  "image",
  "plain",
  "java-archive",
]

export const WEBSITE_COLORS = ["grey", "violet", "blue", "green", "red"] as const

export const OPEN = "open" as string
export const CLOSE = "close" as string

export const ONBOARDING_STEPS = [{ label: "แบรนด์" }, { label: "ประเภทคอนเทนต์" }, { label: "ชื่อคลาส" }]

export const BACKGROUND_COLORS = [
  "77dd77",
  "836953",
  "89cff0",
  "99c5c4",
  "9adedb",
  "aa9499",
  "aaf0d1",
  "b2fba5",
  "b39eb5",
  "bdb0d0",
  "bee7a5",
  "befd73",
  "c1c6fc",
  "c6a4a4",
  "cb99c9",
  "ff6961",
  "ff694f",
  "ff9899",
  "ffb7ce",
  "ca9bf7",
]

export const BRANDPAGE = ["contact", "create-brand", "menu"]

export const BANKCODE = [
  {
    Code: "002",
    Bank: "BBL",
    name: {
      th: "กรุงเทพ",
      en: "Bangkok Bank",
    },
  },
  {
    Code: "004",
    Bank: "KBANK",
    name: {
      th: "กสิกรไทย",
      en: "K PLUS",
    },
  },
  {
    Code: "0044",
    Bank: "MAKE",
    name: {
      th: "กสิกรไทย",
      en: "MAKE by KBank",
    },
  },
  {
    Code: "006",
    Bank: "KTB",
    name: {
      th: "กรุงไทย",
      en: "Krungthai NEXT",
    },
  },
  {
    Code: "011",
    Bank: "TTB",
    name: {
      th: "ทหารไทยธนชาต",
      en: "TTB TOUCH",
    },
  },
  {
    Code: "014",
    Bank: "SCB",
    name: {
      th: "ไทยพาณิชย์",
      en: "SCB EASY",
    },
  },
  {
    Code: "017",
    Bank: "CITI",
    name: {
      th: "ซิตี้แบงก์",
      en: "CITY Bank",
    },
  },
  {
    Code: "022",
    Bank: "CIMBT",
    name: {
      th: "ซีไอเอ็มบีไทย",
      en: "CIMB THAI",
    },
  },
  {
    Code: "024",
    Bank: "UOBT",
    name: {
      th: "ยูโอบี",
      en: "UOB TMRW Thailand",
    },
  },
  {
    Code: "025",
    Bank: "BAY",
    name: {
      th: "กรุงศรีอยุธยา",
      en: "KMA",
    },
  },
  {
    Code: "030",
    Bank: "GSB",
    name: {
      th: "ออมสิน",
      en: "MyMo By GSB",
    },
  },
  {
    Code: "067",
    Bank: "TISCO",
    name: {
      th: "ทิสโก้",
      en: "TISCO My Wealth",
    },
  },
  {
    Code: "069",
    Bank: "KK",
    name: {
      th: "เกียรตินาคินภัทร",
      en: "KKP MOBILE",
    },
  },
]

export const IS_SKIP_TERM_OF_SERVICE =
  env.NEXT_PUBLIC_SKIP_TERM_OF_SERVICE === "true" || env.NEXT_PUBLIC_SKIP_TERM_OF_SERVICE === "1"

export const SlipTransactionState = {
  DRAFT: "draft",
  PENDING: "pending",
  CANCELLED: "cancelled",
  DECLINED: "declined",
  APPROVED: "approved",
} as const

export const SlipStatus = {
  MISMATCH: "mismatch",
  DUPLICATE: "duplicate",
  QRCODE_NOT_FOUND: "qrcode_not_found",
  COMPLETED: "completed",
} as const

export const ReasonToNotAllowChangingPackageForFree = {
  MEMBER_EXCEEDED: "member-exceeded",
} as const
export const VerifyStatus = {
  VERIFIED: "verified",
  PARTIALLY_VERIFIED: "partially-verified",
  UNVERIFIED: "unverified",
} as const

export const AccountType = {
  BANK_ACCOUNT: "bank-account",
  PROMPTPAY_PHONE_NUMBER: "promptpay-phone-number",
  PROMPTPAY_NATIONAL_ID: "promptpay-national-id",
} as const

export const PaymentProvider = {
  BANK_ACCOUNT: "bank-account",
  PROMPT_PAY: "prompt-pay",
}
