import { create } from "zustand"

import { createPaymentSetupSlice, type PaymentSetupSlice } from "@/stores/payment-modal-store"

import { createBrandSlice, type BrandSlice } from "./brand-store"
import { createCourseSlice, type CourseSlice } from "./course-store"
import { createfileSlice, type FileSlice } from "./file-store"
import { createClientSessionSlice, type ClientSessionSlice } from "./session-store"
import { createUploadSlice, type UploadSlice } from "./upload-store"

export type CommonState = FileSlice & BrandSlice & CourseSlice & UploadSlice & ClientSessionSlice & PaymentSetupSlice

export const useStore = create<CommonState>()((...a) => ({
  ...createfileSlice(...a),
  ...createBrandSlice(...a),
  ...createCourseSlice(...a),
  ...createUploadSlice(...a),
  ...createClientSessionSlice(...a),
  ...createPaymentSetupSlice(...a),
}))
