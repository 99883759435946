import { type User } from "@/types"
import { type StateCreator } from "zustand"

import { useStore } from "."

export type ClientSessionState = {
  user: User | null
  member: User | null
  status: "authenticated" | "unauthenticated" | "loading"
}

export type ClientSessionAction = {
  update: (user: User | null) => void
}

const initialSessionState: ClientSessionState = {
  user: null,
  member: null,
  status: "loading",
}

export type ClientSessionSlice = ClientSessionState & ClientSessionAction

export const createClientSessionSlice: StateCreator<ClientSessionSlice> = (set) => ({
  ...initialSessionState,
  update: (user) => {
    set(() => ({
      user,
      status: user ? "authenticated" : "unauthenticated",
    }))
  },
})

export type UseSessionOptions = {
  onUpdate?: (user: User | null) => void
}
export type UseSessionReturn = {
  user: User | null
  status: "authenticated" | "unauthenticated" | "loading"
  update: (user: User | null) => void
}
export function useSession(options?: UseSessionOptions): ClientSessionSlice {
  const sessionStore = useStore()
  return {
    user: sessionStore.user,
    member: sessionStore.member,
    status: sessionStore.status,
    update(user) {
      sessionStore.update(user)
      options?.onUpdate?.(user)
    },
  }
}
