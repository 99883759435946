import { type StateCreator } from "zustand"

export interface CourseState {
  courseName: string
  redirectCourse: string
  countAnimationTrigger: boolean
  courseType: string
}

interface CourseAction {
  setCourseName: (courseName: string) => void
  setRedirectCourse: (redirectCourse: string) => void
  resetCourse: () => void
  triggerCountAnimation: () => void
  setCourseType: (courseType: string) => void
}

const initialCourseState: CourseState = {
  courseName: "",
  redirectCourse: "",
  countAnimationTrigger: false,
  courseType: "",
}

const getInitialRedirectCourse = () => {
  if (typeof window === "undefined") return ""
  const redirectCourseStorage = localStorage.getItem("redirectCourse") || ""
  return redirectCourseStorage
}

const getInitialCourseType = () => {
  if (typeof window === "undefined") return ""
  const courseTypeStorage = localStorage.getItem("courseType") || ""
  return courseTypeStorage
}

export type CourseSlice = CourseState & CourseAction

export const createCourseSlice: StateCreator<CourseSlice> = (set) => ({
  ...initialCourseState,
  redirectCourse: getInitialRedirectCourse(),
  courseType: getInitialCourseType(),
  setRedirectCourse: (redirectCourse: string) => {
    set(() => {
      localStorage.setItem("redirectCourse", redirectCourse)
      return { redirectCourse }
    })
  },
  setCourseName: (courseName: string) => {
    set({ courseName })
  },
  resetCourse: () => {
    set(initialCourseState)
  },
  triggerCountAnimation: () => {
    set((state) => ({
      countAnimationTrigger: !state.countAnimationTrigger,
    }))
  },
  setCourseType: (courseType: string) => {
    set(() => {
      localStorage.setItem("courseType", courseType)
      return { courseType }
    })
  },
})
