import { type StateCreator } from "zustand"

export interface BrandState {
  brandName: string
  color?: string
  logo: File | null
  slug: string
}

interface BrandAction {
  setBrandName: (brandName: string) => void
  setColor: (color: string) => void
  setLogo: (logo: File | null) => void
  setSlug: (slug: string) => void
  resetBrand: () => void
}

const initialBrandState: BrandState = {
  brandName: "",
  logo: null,
  slug: "",
}

const getInitialBrandSlug = () => {
  if (typeof window === "undefined") return ""
  const brandSlugStorage = localStorage.getItem("brandSlug") || ""
  return brandSlugStorage
}

const getInitialBrandName = () => {
  if (typeof window === "undefined") return ""
  const brandNameStorage = localStorage.getItem("brandName") || ""
  return brandNameStorage
}

export type BrandSlice = BrandState & BrandAction

export const createBrandSlice: StateCreator<BrandSlice> = (set) => ({
  ...initialBrandState,
  brandName: getInitialBrandName(),
  slug: getInitialBrandSlug(),
  setBrandName: (brandName: string) => {
    set(() => {
      localStorage.setItem("brandName", brandName)
      return { brandName }
    })
  },
  setColor: (color: string) => {
    set({ color })
  },
  setLogo: (logo: File | null) => {
    set({ logo })
  },
  setSlug: (slug: string) => {
    set(() => {
      localStorage.setItem("brandSlug", slug)
      return { slug }
    })
  },
  resetBrand: () => {
    set(initialBrandState)
  },
})
