import { type PaymentProviderType } from "@/types"
import { type StateCreator } from "zustand"

import { type AccountsInformation, type VerifyStatusType } from "@/types/account"
import { type SlipInformation } from "@/types/slip-transactions"
import { AccountType, VerifyStatus } from "@/lib/constants"

export interface PaymentSetupState {
  currentStep: number
  activeAccount: boolean
  verifyStatus: VerifyStatusType
  verifyPassCode: string
  resendPassCode: string
  paymentMethod: PaymentProviderType
  accountInformation: AccountsInformation
  slipInformation: SlipInformation
}

export interface PaymentSetupAction {
  setCurrentStep: (step: number) => void
  setActiveAccount: (value: boolean) => void
  setVerifyStatus: (value: VerifyStatusType) => void
  setVerifyPassCode: (value: string) => void
  setResendPassCode: (value: string) => void
  setPaymentMethod: (value: PaymentProviderType) => void
  setAccountInformation: (value: AccountsInformation) => void
  setSlipInformation: (value: SlipInformation) => void
}

const initialPaymentSetupState: PaymentSetupState = {
  currentStep: 0,
  activeAccount: false,
  verifyStatus: VerifyStatus.VERIFIED,
  verifyPassCode: "",
  resendPassCode: "",
  paymentMethod: "prompt-pay",
  accountInformation: {
    accountName: "",
    bankCode: "",
    accountNumber: "",
    type: AccountType.BANK_ACCOUNT,
  },
  slipInformation: { accountName: "", accountNumber: "", bankCode: "" },
}

export type PaymentSetupSlice = PaymentSetupState & PaymentSetupAction

export const createPaymentSetupSlice: StateCreator<PaymentSetupSlice> = (set) => ({
  ...initialPaymentSetupState,
  setCurrentStep: (step: number) => {
    set({ currentStep: step })
  },
  setActiveAccount: (value: boolean) => {
    set({ activeAccount: value })
  },
  setVerifyStatus: (value: VerifyStatusType) => {
    set({ verifyStatus: value })
  },
  setVerifyPassCode: (value: string) => {
    set({ verifyPassCode: value })
  },
  setResendPassCode: (value: string) => {
    set({ resendPassCode: value })
  },
  setPaymentMethod: (value: PaymentProviderType) => {
    set({ paymentMethod: value })
  },
  setAccountInformation: (value: AccountsInformation) => {
    set({ accountInformation: value })
  },
  setSlipInformation: (value: SlipInformation) => {
    set({ slipInformation: value })
  },
})
