import { type StoredFile } from "@/types"
import { type StateCreator } from "zustand"

export interface FileState {
  files: StoredFile[]
}

export interface FileAction {
  addFiles: (files: StoredFile[]) => void
  removeFile: (fileId: string) => void
  resetFiles: () => void
  updateFile: (file: StoredFile) => void
}

const initialFileState: FileState = {
  files: [],
}

export type FileSlice = FileState & FileAction

export const createfileSlice: StateCreator<FileSlice> = (set) => ({
  ...initialFileState,
  addFiles: (files: StoredFile[]) => {
    set((state) => ({ files: [...state.files, ...files] }))
  },
  removeFile: (fileId: string) => {
    set((state) => ({
      files: state.files.filter((file) => file.id !== fileId),
    }))
  },
  resetFiles: () => {
    set(() => initialFileState)
  },
  updateFile: (file: StoredFile) => {
    set((state) => ({
      files: state.files.map((f) => (f.id === file.id ? file : f)),
    }))
  },
})
